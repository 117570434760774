import React from 'react';
import { Link } from "gatsby";

function Pagination({
  humanPageNumber,
  numberOfPages = 1,
  previousPagePath = '',
  nextPagePath = ''
}) {
  if (numberOfPages > 1) {
    return (<div>
      {Boolean(previousPagePath.length) && <Link to={previousPagePath}>Previous Page</Link>}
      Page {humanPageNumber} of {numberOfPages}
      {Boolean(nextPagePath.length) && <Link to={nextPagePath}>Next Page</Link>}
    </div>);
  }
  return null;
}

export default Pagination;