import React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import BlockContent from '@sanity/block-content-to-react';
import { defaultSerializers } from '../utils/serializers';
import { addHotspot } from '../utils/imageUrl';
import Pagination from '../components/pagination';
import ProjectLink from '../components/projectLink';
import toPlainText from '../utils/toPlainText';
import truncate from 'lodash/truncate';

const ProjectList = props => {
  const { data } = props;
  const projectEntries = data.allSanityProject.edges;
  const { _rawIntro, bannerImage } = data.sanityPage;

  return (
    <Layout
      dominantColour={bannerImage.asset.metadata.palette.dominant.background}
      textColour={bannerImage.asset.metadata.palette.dominant.title}
      headerImage={addHotspot(
        bannerImage.asset.fluid.src,
        data.sanityPage.bannerImage.hotspot
      )}
    >
      <SEO title="Projects" />
      <section className="page-content page-content--project-list">
        <header>
          <h1>Projects</h1>
        </header>
        <BlockContent blocks={_rawIntro} serializers={defaultSerializers} />
        {projectEntries.map(({ node }) => {
          const image = node.featuredImage || node.mainImage;
          const shortDescription = node.summary
            ? node.summary
            : truncate(toPlainText(node._rawContent), {
                length: 160,
              });
          return (
            <ProjectLink
              key={node.id}
              title={node.title}
              shortDescription={shortDescription}
              image={image.asset.fixed}
              to={`/projects/${node.slug.current}/`}
              slug={node.slug}
            />
            // <li key={node.id}><time>{node._createdAt}</time><Link to={`/projects/${node.slug.current}/`}>{node.title}</Link></li>
          );
        })}
        <Pagination {...props.pageContext} />
      </section>

      {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
    </Layout>
  );
};

export const pageQuery = graphql`
  query SanityProjectQuery($limit: Int!, $skip: Int!) {
    sanityPage(slug: { current: { eq: "projects" } }) {
      title
      _rawIntro(resolveReferences: { maxDepth: 5 })
      bannerImage {
        hotspot {
          x
          y
        }
        asset {
          metadata {
            palette {
              dominant {
                background
              }
            }
          }
          fluid(maxWidth: 1600, maxHeight: 400) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
    allSanityProject(
      sort: { order: DESC, fields: [date, title] }
      filter: { private: { eq: false } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          date
          _rawContent
          summary
          id
          slug {
            current
          }
          title
          mainImage {
            asset {
              fixed(width: 200, height: 200) {
                ...GatsbySanityImageFixed
              }
            }
          }
          featuredImage {
            asset {
              fixed(width: 200, height: 200) {
                ...GatsbySanityImageFixed
              }
            }
          }
        }
      }
    }
  }
`;

export default ProjectList;
